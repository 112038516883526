/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "Signatu provides a high-performance API for registering and querying Consent Events for your website and\nother services. We also provide a ", React.createElement(_components.a, {
    href: "/docs/api/v1/sdk"
  }, "developer SDK"), " with a range of themable\n", React.createElement(_components.a, {
    href: "https://reactjs.org/"
  }, "React"), " UI components. You can also use the REST API directly in any other UI\nframework such as Vue and Angular, or from your mobile app."), "\n", React.createElement(_components.p, null, "Signatu uses OAuth 2.0 bearer tokens to authorize API requests. Depending on your security needs you\nmay need to proxy requests from your own servers. See ", React.createElement(_components.a, {
    href: "/docs/api/v1/security"
  }, "Security"), " for additional details."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/5006d2ec86f021fc0a2ae3c179559232/consent.svg",
    alt: "Consent Architecture"
  })), "\n", React.createElement(_components.h2, {
    id: "legal-basis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#legal-basis",
    "aria-label": "legal basis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Legal basis"), "\n", React.createElement(_components.p, null, "Getting Consent from your users is important to enable you to lawfully process personal- and other data.\nIt also makes sense to build trustful relationships with your users - ask them first. When you use\nconsent as the legal basis, you will require an active and valid consent (“opt-in”) form the user."), "\n", React.createElement(_components.p, null, "Note that the Signatu consent service can be used for “opt-out” scenarios as well, where you use\nanother Legal basis (e.g., legitimate interest) for processing. In that case Signatu will consider\nthe lack of action as a permission, and allow users to actively refuse."), "\n", React.createElement(_components.h2, {
    id: "integration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration",
    "aria-label": "integration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration"), "\n", React.createElement(_components.p, null, "You can register ", React.createElement(_components.a, {
    href: "/docs/api/v1/integration/webhooks"
  }, "webhooks"), " for consent events, and\nSignatu will notify your other systems (such as CRM) whenever we receive a new consent event."), "\n", React.createElement(_components.h2, {
    id: "write-once-log",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#write-once-log",
    "aria-label": "write once log permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Write-once log"), "\n", React.createElement(_components.p, null, "You can think the Consent service as a write-once log of immutable events. Once written,\nSignatu do not allow you or anybody to change the event. This ensures that there will be\nno questions about the information in the consent event, and will ease your compliance\nwith regulatory requirements."), "\n", React.createElement(_components.p, null, "Events are available for read (i.e., check the status for a customer), and Signatu provides\nanalytics tools to help you understand trends."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
